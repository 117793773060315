<template>
  <form @submit.prevent="onSubmit(1)">
    <div class="md-layout mb-10">
      <div class="md-layout-item">
        <h2 class="text-uppercase text-2xl">Create new question <small class="opacity-50">{{ type }}</small></h2>
      </div>
      <div class="md-layout-item flex justify-end">
        <md-button :to="{name: `pte-question.${type}`}" class="rounded">
          <md-icon>list</md-icon>  Back Question List
        </md-button>
      </div>
    </div>
    <div class="md-layout">
      <!-- 01 ) full width row -->
      <div class="md-layout-item md-layout md-gutter md-size-100 mb-6">
        <div class="md-layout-item md-size-50 md-small-size-100">
          <SelectComponent
            :placeholder="false"
            label="Question Type"
            class="w-64"
            :items="questionTypes"
            v-model="form.question_type_id"
            disabled
            />
        </div>
        <div class="md-layout-item md-size-50 md-small-size-100 mt-3 py-2 flex justify-end">
          <md-button @click="onChooseSamleAnswer" class="ml-0 rounded p-3 bg-victoria text-white">
            Choose Sample Answer
          </md-button>
          <md-button @click="onShowStudyGuide" class="rounded p-3 bg-green text-white">
            Set Study Guide
          </md-button>
        </div>
      </div>
      <!-- 02 ) full width row -->
      <div class="md-layout-item md-layout md-gutter md-size-100">
        <div class="md-layout-item md-size-100 md-small-size-100">
          <RadioButtonComponent
            label="Prompt"
            :items="[{id:'DEFAULT', label: 'DEFAULT'}, {id:'CUSTOM', label: 'CUSTOM'}]"
            v-model.trim="form.prompt_type"
          />
          <TextEditor
            class="mb-4"
            label-class="text-sm text-uppercase mb-1"
            label="Prompt text"
            v-if="form.prompt_type == 'CUSTOM'"
            v-model.trim="form.prompt"
          />
          <div
            v-if="form.prompt_type == 'DEFAULT'"
            class="p-2 bg-wild-sand rounded mt-2 mb-3 text-base line-height-27"
            v-html="form.prompt"
            >
          </div>
        </div>
        <div class="md-layout-item flex flex-wrap align-center md-size-50 md-small-size-100" v-if="type == 'speaking'">
          <RadioButtonComponent
            label="Pre Time"
            :items="[{id:'DEFAULT', label: 'DEFAULT'}, {id:'CUSTOM', label: 'CUSTOM'}]"
            v-model.trim="form.preparation_time_type"
           
          />
          <vue-timepicker close-on-complete v-model="preTime" format="mm:ss" :disabled="form.preparation_time_type == 'DEFAULT'"></vue-timepicker>
        </div>
        <div class="md-layout-item flex flex-wrap align-center md-size-50 md-small-size-100">
          <RadioButtonComponent
            label="Answer Time"
            :items="[{id:'DEFAULT', label: 'DEFAULT'}, {id:'CUSTOM', label: 'CUSTOM'}]"
            v-model.trim="form.answer_time_type"
          />
          <vue-timepicker
            close-on-complete
            v-model="answerTime"
            format="mm:ss"
            :disabled="form.answer_time_type == 'DEFAULT'"
            ></vue-timepicker>
        </div>
        
      </div>
      <!-- 03 ) full width row -->
      <div class="md-layout-item md-layout md-gutter md-size-100 mt-6">
        <div class="md-layout-item">
          <InputFieldComponent
            label="Question Index"
            v-model.trim="form.index"
          />
        </div>
        <div class="md-layout-item md-layout">
          <div class="md-layout-item flex">
            <SelectComponent
              :items="source"
              placeholder="Select Source"
              label="Source"
              class="basis"
              v-model.trim="$v.form.source.$model"
              :message="!$v.form.source.required && $v.form.source.$dirty ? 'Field is required' : null"
            />
          </div>
          <div class="md-layout-item flex">
            <SelectComponent
              :items="frequency"
              placeholder="Select Frequency"
              label="FREQUENCY"
              class="basis"
              v-model.trim="form.frequency"
            />
          </div>
          <div class="md-layout-item flex justify-end  flex-col">
            <md-button
              class="rounded bg-victoria text-white text-capitalize"
              @click="setExplanation = !setExplanation"
              >
              Set Explanation
            </md-button>
          </div>
        </div>
      </div>
      <SetExplanation v-if="setExplanation" />
      <components ref="child" v-model="form.title" :is="getSelectedQuestionType.component"></components>
      <!-- 07 ) full width row -->
      <div class="md-layout-item md-layout md-gutter md-size-100 mt-6">
        <div class="md-layout-item flex justify-end">
          <md-button class="bg-alto rounded" @click="onSubmit(0)">Save as dreft</md-button>
          <md-button type="submit" class="bg-victoria rounded text-white mr-0">Update</md-button>
        </div>
      </div>
    </div>
    <md-dialog :md-active.sync="showSecondDialog">
       <components :is="dialogComponent" @close="showSecondDialog = false"></components>
    </md-dialog>
  </form>
</template>

<script>
import {
  TextEditor,
  SelectComponent,
  InputFieldComponent,
  RadioButtonComponent,
} from "@/components";
import questionComponents from "@/components/molecule/question/items";
import SetExplanation from "@/components/molecule/question/SetExplanation";
import ChooseSampleAnswer from "@/components/molecule/question/ChooseSampleAnswer";
import StudyGuide from "@/components/molecule/question/StudyGuide";
import VueTimepicker from 'vue2-timepicker'
import cms from "@/data/cms"
import { mapMutations, mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import { secondToTime, timeToSecond } from '@/utils'
export default {
  name: "TheQuestionEditView",
  components: {
    TextEditor,
    SelectComponent,
    SetExplanation,
    VueTimepicker,
    StudyGuide,
    RadioButtonComponent,
    ChooseSampleAnswer,
    InputFieldComponent,
    ...questionComponents
  },
  data() {
    return {
      preTime: '00:00',
      answerTime: '',
      setExplanation: false,
      dialogComponent: 'ChooseSampleAnswer',
      showSecondDialog: false,
      source: cms.source,
      frequency: cms.frequency,
      form: {
        question_type_id: '',
        index: "",
        prompt_type: "",
        prompt: "",
        preparation_time_type: "",
        preparation_time: 0,
        answer_time_type: "",
        answer_time: 0,
        source: "",
        frequency: "",
        active: "1",
        title: '',
        type: "question",
        question_options: [],
      },
    };
  },
  computed: {
    ...mapGetters({
      questionTypes: "question/getQuestionTypes",
      getSelectedQuestionType: "question/getSelectedQuestionType",
      getSingleQuestion: "question/getSingleQuestion",
    }),
    type() {
        return this.$route.query.type
    },
    questionId() {
        return this.$route.params.id
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
      setShowSnackbar: "setShowSnackbar",
      setSelectedQuestionType: "question/setSelectedQuestionType"
    }),
    ...mapActions({
      actQuestionDetails: 'question/actQuestionDetails',
      actGetQuestionTypes: "question/actGetQuestionTypes",
      actQuestionTypeDetails: "question/actQuestionTypeDetails",
      actUpdateQuestion: "question/actUpdateQuestion",
    }),
    onSubmit(active) {

      this.form.preparation_time = timeToSecond(this.preTime);
      this.form.answer_time = timeToSecond(this.answerTime);

      this.$v.$touch();

      if(this.$v.$invalid) {
        return;
      }
     
      this.form.active = active;
     
      this.form = {...this.form, ...this.$refs.child.form}
      this.actUpdateQuestion({
        questionId: this.questionId,
        payload: this.form
      })
        .then(message => {
          this.setShowSnackbar(message);
          this.$router.go(-1);
        }).catch(error => {
           this.setShowSnackbar(error.response.data.message);
        });
    },
    
    onChooseSamleAnswer() {
      this.dialogComponent = 'ChooseSampleAnswer';
      this.showSecondDialog = true;
    },
    
    onShowStudyGuide() {
      this.dialogComponent = 'StudyGuide';
      this.showSecondDialog = true;
    },

  },
  validations: {
    form: {
      prompt_type: {required},
      answer_time_type: {required},
      source: {required}
    },
  },
  mounted() {
    // api call for writing types question
    this.actGetQuestionTypes(`?section=${this.type}`);

    this.actQuestionDetails(this.questionId).then( item => {
      let { question_type, prompt , answer_time } = item;
       this.setSelectedQuestionType(question_type);

       this.form.question_type_id = question_type.id;
       this.form.prompt_type = item.prompt_type;
       this.form.prompt = prompt;
       this.form.answer_time_type = item.answer_time_type;
       this.answerTime = secondToTime(answer_time)
       this.form.index = item.index;
       this.form.title = item.title;
       this.form.source = item.source;
       this.form.frequency = item.frequency;

    });

  }
};
</script>